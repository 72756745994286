<template>
	<shutter-panel ref="shutterPanel" />
</template>

<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
    import ShutterHorse from '@/mixins/shutters-manager/HorseCourrier.js'

	export default {
		name: 'HorseCourrier',
		mixins: [Shutter, ShutterHorse],
		props: ['horse_id'],
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.setupCourrierType(this.horse_id)
			},
		}
	}

</script>